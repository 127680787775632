import Media from "../../components/media/Media";
import { NameSplit, Split } from "../../components/split/Split";
import "./home.scss"

export default function Home() {
  let api: number | undefined = undefined;
  return (
    <div className="home">
      <div className="shortAbout">
        <img src={require("../../assets/home/zdjęcie 2.jpg")} alt=""></img>
        <div className="right">
          <div className="text">
            <h2> Cześć, <br /> Mam na imię <span>Ewa</span>. Jestem <span>trenerem biegania</span> oraz <span>fizjoterapeutką</span>. </h2>
            <p>
              Istotą mojej pracy jest profesjonalny trening, wspaniała przygoda,
              możliwość rozwoju i osiąganie zamierzonych celów. Jestem tutaj
              aby promować aktywny i zdrowy styl życia. Chcę motywować, uczyć
              i zarażać miłością do biegania innych.
              <br /> <br />
              Marzysz o rozpoczęciu przygody z bieganiem? Jesteś zmotywowany
              i gotowy do pracy? Jestem do Twojej treningowej dyspozycji.
            </p>
          </div>
          <div className="contact"><a href="/#/Contact">STARTUJĘ</a></div>
        </div>

      </div>
      {api ? <>
        <NameSplit text={"Opinie"} />
        <div className="googleOpinion">
        </div>
      </>
        : null
      }


      <Split />
      <Media />
    </div>
  )
}