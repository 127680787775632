import "./about.scss"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faTrophy, faGem } from '@fortawesome/free-solid-svg-icons'
import Media from "../../components/media/Media"
import { Split } from "../../components/split/Split"

export default function About() {

  const edu_list: Array<string> = [
    "Instruktor lekkiej Atletyki PZLA",
    "Trener Personalny",
    "Magister Fizjoterapii",
    "Magister Turystyki Zdrowotnej",
    "Technik Masażysta"
  ]

  const achieved_list: Array<string> = [
    "Mistrzostwa Europy w biegach górskich: I miejsce Morbegno 2005",
    "Mistrzostwa Świata Juniorów w biegach górskich: 48 miejsce Bursa 2006",
    "Halowe Mistrzostwa Polski Juniorów Młodszych: 600m I miejsce i REKORD POLSKI Spała 2007",
    "Mistrzostwa Polski w biegach górskich: I miejsce Góra Św. Anny 2007",
    "Mistrzostwa Świata Juniorów Młodszych: 800m VII miejsce Ostrava 2007",
    "Mistrzostwa Świata Juniorów: 4x400m XII miejsce Bydgoszcz 2008",
    "Mistrzostwa Polski Juniorów: 800m II miejsce Toruń 2008",
    "Halowe Mistrzostwa Polski Juniorów: 800m I miejsce Spała 2009",
    "Młodzieżowe Mistrzostwa Polski: 800m III miejsce Radom 2012",
    "Mistrzostwa Polski Seniorów w biegach przełajowych: I miejsce drużynowo Kraków 2014"
  ]

  const personal_best_list = [
    "800m- 2:03,85",
    "5km- 18:23",
    "10km- 39:25",
    "Półmaraton- 1:35:40",
    "Maraton- 3:48:15"
  ]

  return (<>
    <div className="about">
      <div className="aboutText">
        <h1 className="title">Jestem Ewa!</h1>
        <h2>Staram się iść z uśmiechem przez życie i wykorzystywać je jak najlepiej potrafię.</h2>
        <p>
          Sport był obecny w moim życiu od zawsze. W 2004r niewinne uczestnictwo w zawodach sportowych
          przerodziło się w pasję do biegania.
          </p>
          <p>
            
          Przez 14 lat wyczynowo trenowałam
          biegi średnie, po czym
          rozpoczęłam zdobywanie doświadczenia jako trener i fizjoterapeutka.
        </p>
        <p>
          Bieganie nauczyło mnie, że oddawanie się pasji jest tym, dzięki czemu czujemy się szczęśliwsi…
          uwielbiam pracę z ludźmi, to ona stała się moim źródłem rozwoju i inspiracji.
        </p>
        <p>
          A poza bieganiem? Rodzina, podróże, nowe doświadczenia, no i kawa!
        </p>
      </div>
      <div className="aboutImg">
        <img src={require("../../assets/about/1.jpg")} alt="about" />
        <img src={require("../../assets/about/2.jpg")} alt="about" />
        <img src={require("../../assets/about/3.jpg")} alt="about" />
        <img src={require("../../assets/about/4.jpg")} alt="about" />
      </div>
      <Split />
      <div className="segment">
        <h2> Wykształcenie </h2>
        <ul>
          {
            edu_list.map((e) => {
              return (<>
                <li>
                  <FontAwesomeIcon icon={faGraduationCap} />
                  <p> {e} </p>
                </li>
              </>)
            })
          }
        </ul>
      </div>
      <div className="segment">
        <h2> Moje największe osiągnięcia </h2>
        <ul>
          {
            achieved_list.map((e) => {
              return (<>
                <li>
                  <FontAwesomeIcon icon={faTrophy} />
                  <p> {e} </p>
                </li>
              </>)
            })
          }
        </ul>
      </div>
      <div className="segment">
        <h2> Życiówki </h2>
        <ul>
          {
            personal_best_list.map((e) => {
              return (<>
                <li>
                  <FontAwesomeIcon icon={faGem} />
                  <p> {e} </p>
                </li>
              </>)
            })
          }
        </ul>
      </div>

      <Split />
      <h2>
        Jeżeli chcesz mnie poznać bliżej zapraszam na moje media społecznościowe.
      </h2>
      <Media />
    </div>
  </>)
}