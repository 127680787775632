import "./topbar.scss"

export default function Topbar() {
  return (
    <>
      <img className="imgTopbar" src={require("../../assets/topbar/nolimits-transformed.jpeg")} alt={require("../../assets/topbar/nolimits.jpg")}/>
      <div className="topbar">
        <a href="/#/">        {"Strona Główna".toUpperCase()}</a>
        <a href="/#/About">   {"O mnie".toUpperCase()}</a>
        <a href="/#/Running"> {"Zacznij przygodę z bieganiem".toUpperCase()}</a>
        <a href="/#/Teraphy"> {"Usługi fizjoterapeutyczne".toUpperCase()}</a>
        <a href="/#/Contact"> {"Kontakt".toUpperCase()} </a>
      </div>
    </>
  )
}