import "./split.scss"
export function Split() {
  return <div className="split" />
}

export function NameSplit(props: {text: string, className?: string}){
  return (
    <>
      <div className={"nameSplit"} />
      {
        <h1 className={""+props.className}>{props.text}</h1>
      }
    </>

  )
}