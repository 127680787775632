import React from 'react';
import './App.scss';
import Topbar from './components/topbar/Topbar';
import Home from './pages/home/Home';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/about/About';
import Running from './pages/running/Running';
import Teraphy from './pages/teraphy/Teraphy';
import Contact from './pages/contact/Contact';

function App() {
  return (
    <div className="App">
      <Topbar />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Running" element={<Running />} />
          <Route path="/Teraphy" element={<Teraphy />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
