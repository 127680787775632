import { useEffect, useState } from 'react';
import Media from '../../components/media/Media'
import { Split } from '../../components/split/Split'
import './contact.scss'
export default function Contact() {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [text, setText] = useState<string>("");

  const [canSend, setCanSend] = useState<boolean>(false);

  const mailRegEx = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
  const phoneRegEx = new RegExp('^([+]?[\\s0-9]+)?(\\d{3}|[(]?[0-9]+[)])?([-]?[\\s]?[0-9])+$');

  useEffect(() => {
    if (mailRegEx.test(email) && phoneRegEx.test(phone) && email.length > 0 && phone.length > 0 && text.length > 0 && name.length > 0) {
      setCanSend(true);
      console.log(canSend);
    } else {
      setCanSend(false);
      console.log(canSend);
    }
    // eslint-disable-next-line
  }, [email, phone, text]);

  const handleSubmit = () => {
    let data = {
      name: name,
      email: email,
      phone: phone,
      text: text
    }
    console.log(JSON.stringify(data));

    const res = fetch("http://ewajacniak.pl/api/", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        text: text
      }),
    });
    console.log(res);
  }
  return (
    <div className='contact'>
      <h1 className="title">Chcesz rozpocząć przygodę z bieganiem? Chętnie Ci pomogę.</h1>
      <Split />
      <p className='short'>
        Mam na imię Ewa i będzie mi miło, jeśli będziesz się do mnie zwracać po imieniu. 
        <br/><br/>
        Jeśli masz jakieś pytania, zapraszam do kontaktu.
      </p>
      <div className="form">
        <div>
          <label> Imie i nazwisko
            <input type='text' name='name' value={name} onChange={(e) => setName(e.target.value)} aria-disabled={!(name.length > 0) ? "true" : "false"} />
          </label>
          <label> Email
            <input type='text' name='email' value={email} onChange={(e) => setEmail(e.target.value)} aria-disabled={!mailRegEx.test(email) ? "true" : "false"} />
          </label>
          <label> Numer telefonu
            <input type='text' name='phone' value={phone} onChange={(e) => setPhone(e.target.value)} aria-disabled={!phoneRegEx.test(phone) ? "true" : "false"} />
          </label>
        </div>
        <textarea name='text' placeholder='tekst...' value={text} onChange={(e) => setText(e.target.value)} />
        <button name='submit' disabled={!canSend} onClick={handleSubmit} > Wyślij </button>
      </div>
      <Split />
      <Media />
    </div>
  )
}