import Media from '../../components/media/Media'
import { NameSplit, Split } from '../../components/split/Split'
import './teraphy.scss'

const offers = [
  "TERAPIA MANUALNA",
  "MASAŻ LECZNICZY",
  "MASAŻ SPORTOWY",
  "KINESIOTAPING",
  "TRENING MEDYCZNY",
  "LASER WYSOKOENERGETYCZNY",
  "FALA UDERZENIOWA",
]

export default function Teraphy() {
  return (
    <div className="teraphy">
      <div className="intro">

        <h1>mgr Ewa Jacniak</h1>
        <Split />
        <p>Zwykle nie zwracamy uwagi na skargi naszego ciała i zapominamy o istotnym elemencie życia człowieka jakim jest zdrowie.
          <br />
          Najczęściej je doceniamy jak je tracimy.
          <br /> <br />
          Pomagam osobom, które cierpią z powodu bólu, są po urazach, czy kontuzjach, jak również sportowcom, <br/> którzy chcą zwiększyć proces regeneracji mięśni poprzez masaż sportowy.
        </p>
      </div>
      <NameSplit text={'USŁUGI'} className = "offer"/>
      <Split />
      <ul>
        {offers.map((o, key) => <li key={key}>{o.toLocaleUpperCase()}</li>)}
      </ul>
      <Split />
      <h2>
        Jeśli potrzebujesz pomocy napisz do mnie
      </h2>
      <div className="contact"><a href="/"> Kontakt </a></div>
    </div>
  )
}