import { Split } from "../../components/split/Split"
import "./running.scss"

interface offer {
  title: string;
  description: string;
  text_under: {
    text: string[];
    link: string | null;
  };
  img: string;
  rotation: boolean;
}

function Offer(props: { offer: offer }) {
  return (
    <div className="offer">
      <div className="textWPhoto">
        {props.offer.rotation ?
          <img src={require(`../../assets/running/` + props.offer.img)} alt="" /> : null
        }
        <div>

          <h1 className="minorTitle">{props.offer.title.toUpperCase()}</h1>
          <p>{props.offer.description}
            <br /> <br />
            <span>
              {
                props.offer.text_under.link ? <a href={props.offer.text_under.link}>{props.offer.text_under.text.map(e => <>{e}<br /></>)}</a> : <>{props.offer.text_under.text.map(e => <>{e}<br /></>)}</>
              }
            </span>
          </p>
        </div>
        {!props.offer.rotation ?
          <img src={require(`../../assets/running/` + props.offer.img)} alt="" /> : null
        }
      </div>
    </div>
  )
}

const offers: offer[] = [
  {
    title: `Plany treningowe`,
    description: `
    Plany treningowe rozpisuję pod konkretny cel zawodnika. Układane są na podstawie aktualnej dyspozycji treningowo- startowej maksymalnie dwa tygodnie do przodu, kontroluje postępy, omawiam treningi i dokonuje ewentualnych korekt. W planach uwzględniam dodatkowe aktywności, starty w zawodach, treningi grupowe, odnowę biologiczną, ćwiczenia uzupełniające, czy też trening zastępczy. Przed podjęciem współpracy przeprowadzam wywiad i ustalam założenia treningowe. Po czym planuję trening. Mamy kontakt e-mail, w razie potrzeby także telefoniczny.`,
    text_under: {
      text: [`Zapraszam do kontaktu`],
      link: '/#/Contact'
    },
    img: `image1.jpg`,
    rotation: false,
  },
  {
    title: `Treningi Grupowe`,
    description: `Treningi grupowe to połączenie biegania z treningiem uzupełniającym i elementami treningu funkcjonalnego, dzięki którym poprawisz swoją technikę biegu, mobilność stawów, zwiększysz wytrzymałość i siłę mięśni. Pracuję nad kompleksową sprawnością podopiecznych, dostosowuje trening do indywidualnych możliwości, staram się, aby trening był maksymalnie bezpieczny. Trenowanie w grupie może być świetną motywacją!`,
    text_under: {
      text: [`Dołącz do Naszej drużyny`],
      link: 'https://www.instagram.com/ewajacniak'
    },
    img: `image3.jpg`,
    rotation: true,
  },
  {
    title: `Akademia Zdrowia `,
    description: `Na platformie treningowo- fizjoterapeutycznej AKADEMIA ZDROWIA znajdziesz cały trening uzupełniający bieganie, jak również testy sprawnościowe i porady od fizjoterapeuty. Krok po kroku prowadzę Cię przez proces treningu, tak żeby był efektywny i bezpieczny. Każdy trening jest filmem instruktażowym, który możesz wplatać w swój regularny plan treningowy. Trening możesz włączyć wszędzie gdzie masz dostęp do internetu.
    `,
    text_under: {
      text: [`Poznaj szczegóły`],
      link: 'http://akademia.ewajacniak.pl',
    },
    img: `image2.jpg`,
    rotation: false,
  },
  {
    title: `OBOZY SPORTOWE`,
    description: `Obóz biegowy to wspaniały czas, kiedy możesz skupić się wyłącznie na treningu i odpoczynku. Zapewniam intensywnie spędzony czas na sportowo, w hotelu, wyżywienie, profesjonalne treningi, sesje regeneracyjne, odnowę biologiczną, solidną dawkę wiedzy i motywacji oraz wspaniałą atmosferę. Poziom trudności treningów jest dobierany indywidualnie. Dojazd we własnym zakresie.
    `,
    text_under: {
      text: [`Naładuj akumulatory pozytywną energią`,
        `Dołącz do Naszego szczęśliwego CAMP TEAM'u.`],
      link: null,
    },
    img: `image4.jpg`,
    rotation: true,
  }
]

export default function Running() {
  return (
    <div className="running">
      <h1 className="title">{"Poznaj Moją Ofertę".toUpperCase()}</h1>
      <Split />
      {offers.map(o => <Offer offer={o} />)}
      <Split />
      <div className="media">
        <a href="https://www.instagram.com/ewajacniak"><img src={require("../../assets/home/ewajacniak_qr.png")} alt="" /></a>
        <a href="https://www.facebook.com/ewajacniaknolimits/"><img src={require("../../assets/home/Facebook_logo.png")} alt="" /></a>
      </div>
    </div>
  )
}